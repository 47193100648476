@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','Inria Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*{
  font-family: 'Inria Sans';font-size: 22px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.box{
  border: 1px solid black;
}
.customScroll{
  position: fixed;
  right:0;
 bottom: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 5%;
  /* height: 100vh; */
  z-index: 100;
}

.filledcircle{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
  margin: 10px 10px 0px 0px;
  animation-name: scrollup;
  animation-duration: .2s;
  animation-iteration-count: 1;
  animation-timing-function:ease-in-out;
  animation-fill-mode:forwards
}
.filledRec{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
  margin: 10px 10px 0px 0px;
  animation-name: scrolldown;
  animation-duration: .2s;
  animation-iteration-count: 1;
  animation-timing-function:ease-in-out;
  animation-fill-mode:forwards
}

.normalDiv{
  width: 12px;
  height: 12px;
  border-radius: 50%;
  animation-name: scrollright;
  animation-duration: .3s;
  animation-iteration-count: 1;
  animation-timing-function:ease-in-out;
  animation-fill-mode:forwards;
  box-shadow: 4px 6px 4px 0px rgba(0, 0, 0, 0.25)
}
.elongatedDiv{
  width: 12px;
  height: 12px;
  border-radius: 50%;
  animation-name: scrollleft;
  animation-duration: .5s;
  animation-iteration-count: 1;
  animation-timing-function:ease-in-out;
  animation-fill-mode:forwards;
  box-shadow: 4px 6px 4px 0px rgba(0, 0, 0, 0.25)
}



@keyframes scrolldown {
  0% {
    border-radius: 50%;
   
    height: 10px;
    width: 10px;
   
  }
  100% {
    height: 30px;
    width: 10px;
    border-radius: 20px;
    
  }
}

@keyframes scrollup {
  0% {
    height: 30px;
    width: 10px;
    border-radius: 20px;
   
  }
  100% {
    border-radius: 50%;
   
    height: 10px;
    width: 10px;
   
    
  }
}
@keyframes scrollleft {
  0% {
    border-radius: 50%;
   
    height: 12px;
    width: 12px;
   
  }
  100% {
    height: 12px;
    width: 28px;
    border-radius: 20px;
    
  }
}

@keyframes scrollright {
  0% {
    height: 12px;
    width: 28px;
    border-radius: 20px;
   
  }
  100% {
    border-radius: 50%;
   
    height: 12px;
    width: 12px;
   
    
  }
}
::-webkit-scrollbar {
  width: 5px;  }
::-webkit-scrollbar-track {
  background: #5AB3F3; 
}

::-webkit-scrollbar-thumb {
  background: #002D68;  
}

p{
  text-align: center;
  /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  font-family: Inria Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.AutoXEngineGradient{
  background-image: linear-gradient(180deg, #05316B 0.01%, #7A91B0 100%);
}

.notifyMeButton{
 border-radius: 40px;
background: linear-gradient(90deg, #BB1E1E 0%, #E74242 100%);
/* box-shadow: 4px 6px 4px 0px rgba(0, 0, 0, 0.25); */
position: absolute;
top:0;
right:0;
margin: 21px 21px 0px 0px;
color: #FFF;
text-align: center;
/* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
font-size: 20px;
font-weight: 300;
line-height: normal;
padding: 14px;
 
}
.homeXR-gradient{
  background: linear-gradient(180deg, #030303 0%, #2B2B2B 100%);
}
.coming-soon{
  border-radius: 60px;
  border: 1px solid #FFF;
  background: none;
  color: #FFF;
  text-align: center;
  /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  font-size: 20px;
  font-weight: 300;
  line-height: normal;
  margin-right: 5px;
  cursor:auto;

}
.notifyFlex{
  border-radius: 40px;
  background: linear-gradient(90deg, #BB1E1E 0%, #E74242 100%);
  /* box-shadow: 4px 6px 4px 0px rgba(0, 0, 0, 0.25); */
  color: #FFF;
  text-align: center;
  /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  font-size: 15px;
  font-weight: 300;
  line-height: normal;
  padding: 14px;
  margin-left: 5px;
 
}

 
.video-container-gradient {
  -webkit-mask-image: linear-gradient(180deg, #FFF -99.7%, #002D68 100%), linear-gradient(to right, rgba(0,0,0,.3), rgba(0,0,0,0));
  -webkit-mask-size: 100% 50%;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: left top, left bottom;
}
 
.red{
  background: red;
}
.swiper-pagination {
	position: absolute;
	top: 10px;
	right: 10px;
	width: auto !important;
	left: auto !important;
	margin: 0;
}